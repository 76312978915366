// LOCAL URL
// var baseUrl = "http://api.recette.easypm.volkeno.com";

// RECETTE API BASE URL
// var baseUrl = 'https://api.recette.easypm.volkeno.com'


//PROD API BASE URL
var baseUrl = "https://api.app.easypm.volkeno.com";




export default baseUrl;
