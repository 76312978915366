// LOCAL URL
// var api = "https://api.recette.easypm.volkeno.com/api/";

// PRE-RECETTE API BASE URL
// var api = 'https://api.recette.easypm.volkeno.com/api/'

// RECETTE  API BASE URL
// var api = "https://api.recette.easypm.volkeno.com/api/" ;

// PROD API BASE URL
var api = 'https://api.app.easypm.volkeno.com/api/'

// DEMO API BASE URL
// var api = 'https://www.demo.guinaw.easypm.sn/api/'

export default api;
